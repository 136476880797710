import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgetPassword from "./pages/ForgetPassword";
import Error from "./pages/Error";
import ProtectedRoute from "./pages/ProtectedRoute";
import { Home, Profile, ProfileEdit } from "./pages/dashboard";
import SharedLayout from "./pages/dashboard/SharedLayout";
import SetPassword from "./pages/SetPassword";
import ManageCatagories from "./pages/dashboard/ManageCatagories";
import SubCategory from "./pages/dashboard/SubCategory";
import Issues from "./pages/dashboard/Issues";
import HomeLayout from "./pages/dashboard/HomeLayout";
import UserData from "./pages/dashboard/UserData";
import ManageBookings from "./pages/dashboard/ManageBookings";
import Help from "./pages/dashboard/Help";
import ManageFAQs from "./pages/dashboard/ManageFAQs";
import TeamDetail from "./pages/dashboard/TeamDetail";
import UserDetail from "./pages/dashboard/UserDetail";
import BookingDetail from "./pages/dashboard/BookingDetail";
import Partners from "./pages/dashboard/Partners";
import PartnerDetails from "./pages/dashboard/PartnerDetails";
import Companies from "./pages/dashboard/Companies";
import CompanyDetails from "./pages/dashboard/CompanyDetails";
import ManageTestimonials from "./pages/dashboard/ManageTestimonials";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <SharedLayout />
              </ProtectedRoute>
            }>
            <Route index element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/edit" element={<ProfileEdit />} />
            <Route path="/catagories" element={<ManageCatagories />} />
            <Route
              path="/catagories/sub-category/:categoryId"
              element={<SubCategory />}
            />
            <Route path="/sub-category" element={<SubCategory />} />
            <Route
              path="/catagories/sub-category/issue/:subCategoryId"
              element={<Issues />}
            />
            <Route path="/bookings" element={<ManageBookings />} />
            <Route
              path="/booking/detail/:bookingId"
              element={<BookingDetail />}
            />
            <Route path="/homelayout" element={<HomeLayout />} />
            <Route path="/userdata" element={<UserData />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/company" element={<Companies />} />
            <Route
              path="/partner/partner-detail/:partnerId"
              element={<PartnerDetails />}
            />
            <Route
              path="/company/company-detail/:companyId"
              element={<CompanyDetails />}
            />
            <Route
              path="/userdata/user-detail/:userId"
              element={<UserDetail />}
            />
            <Route path="/teamdetail" element={<TeamDetail />} />
            <Route path="/help" element={<Help />} />
            <Route path="/help/FAQs" element={<ManageFAQs />} />
            <Route path="/help/testimonials" element={<ManageTestimonials />} />
          </Route>

          <Route path="/register" element={<Register />} />
          <Route path="/setPassword" element={<SetPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgetPassword />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
