import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { HiDotsVertical } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import AddNewUser from "../../components/AddNewUser";
import {
  fetchUser,
  fetchUserFilter,
} from "../../features/Profile/ProfileSlice";
import PaginationBar from "../../components/PaginationBar";
import { Link } from "react-router-dom";

const UserData = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { allUsers, pageSize, totalPages } = useSelector(
    (store) => store.profile
  );
  const [addNew, setAddNew] = useState(false);
  const handleAddNewUser = () => {
    setAddNew((addNew) => !addNew);
  };

  const [isdot, setDot] = useState(false);
  const handleDot = () => {
    isdot === true ? setDot(false) : setDot(true);
  };

  const handleSearchChange = (e) => {
    const value = e?.target?.value;
    setSearchQuery(value);
  };
  const handleSearch = (e) => {
    if (
      e.key === "Enter" &&
      searchQuery?.trim() !== "" &&
      searchQuery !== null
    ) {
      dispatch(fetchUserFilter({ mobile: searchQuery }));
    }
  };

  const handlePageChange = (page) => {
    const queryParams = {
      page: page - 1,
      size: 20,
    };
    setCurrentPage(page);
    dispatch(fetchUser(queryParams));
  };
  useEffect(() => {
    const queryParams = {
      page: 0,
      size: 20,
    };
    dispatch(fetchUser(queryParams));
  }, []);
  return (
    //main div
    <div className="relative my-10 min-h-[60vh] px-4">
      {addNew && <AddNewUser handleAddNewUser={handleAddNewUser} />}
      {/* Search box  */}
      <div className="flex flex-row gap-5 h-20 ">
        <div className="flex justify-start items-center w-2/3 ">
          <div className="flex m-3 w-1/5 h-11 rounded-lg  px-4 bg-gray-100">
            <BsSearch className="my-4 font-extrabold" />
            <input
              type="Search"
              placeholder="Search..."
              onChange={handleSearchChange}
              onKeyDown={handleSearch}
              className="px-3 w-full bg-gray-100 outline-none"
            />
          </div>
        </div>

        {/* add new  */}
        <div className="relative flex w-1/3  m-2 justify-end items-center">
          <div
            className="flex justify-center cursor-pointer items-center text-sm px-4 py-2   bg-primary-max font-semibold rounded hover:scale-110 text-white"
            onClick={handleAddNewUser}>
            Add new
          </div>
        </div>
      </div>

      {/* This div contains the table of user data  */}
      <div className="flex flex-col gap-1">
        <div>
          <div className="h-10 border-x border-t text-center  shadow-md bg-primary-70 text-white  flex items-center   overflow-hidden rounded-md">
            <div className="w-1/12 pl-2">S No.</div>
            <div className="w-1/6 ">Created</div>
            <div className="w-1/6 ">Updated</div>
            <div className="w-1/6">Username</div>
            <div className="w-1/6">User-ID</div>
            <div className="w-1/6">Active</div>
            <div className="w-1/4 ">Mobile</div>
            <div className="w-1/12 ">Action</div>
          </div>
        </div>

        <div className="flex flex-col rounded-xl overflow-hidden">
          {allUsers?.map((elem, index) => {
            return (
              <Link
                to={`/userdata/user-detail/${elem?.id}`}
                key={index}
                className={
                  "flex border items-center px-1 text-center h-10 " +
                  (index % 2 === 0 ? "" : "bg-gray-200")
                }>
                <div className="w-1/12">{index}</div>
                <div className="w-1/6 ">
                  {elem?.createdDateTime.slice(0, 10)}
                </div>
                <div className="w-1/6 ">
                  {elem?.updatedDataTime.slice(0, 10)}
                </div>
                <div className="w-1/6">
                  {elem?.firstName + " " + elem?.lastName}
                </div>
                <div className="w-1/6">{elem?.id}</div>
                <div className="flex justify-center w-1/6">
                  <div className="w-fit px-3 bg-green-400 rounded-md border ">
                    Yes
                  </div>
                </div>
                <div className="w-1/4 ">{elem?.mobile}</div>
                <div className="flex justify-center px-1 w-1/12 ">
                  <HiDotsVertical
                    className="cursor-pointer"
                    onClick={handleDot}
                  />
                  <div
                    className={`handleDot ${
                      isdot === true
                        ? "absolute flex flex-col justify-center items-center w-28 h-24 bg-white z-50 top-60 right-16 rounded-lg shadow-sm shadow-black"
                        : "hidden"
                    }`}>
                    <div className="flex items-center h-1/3 border-b border-black">
                      EDIT
                    </div>
                    <div className="flex items-center h-1/3 border-b border-black">
                      EDIT
                    </div>
                    <div className="flex items-center h-1/3">EDIT</div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      <PaginationBar
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default UserData;
