import React, { useEffect } from "react";
import { useState } from "react";
import { IoAdd } from "react-icons/io5";
import AddCatagory from "../../components/AddCatagory";
import { useDispatch, useSelector } from "react-redux";
import {
  changeVisibility,
  getCatagories,
} from "../../features/Catagory/CatagorySlice";
import { BsThreeDotsVertical, BsHddStackFill } from "react-icons/bs";
import { AiFillEdit, AiTwotoneEyeInvisible } from "react-icons/ai";
import EditCatagory from "../../components/EditCatagory";
import { Link, useNavigate } from "react-router-dom";
import PaginationBar from "../../components/PaginationBar";
import Confirm from "../../components/Confirm";
import GetCode from "../../components/GetCode";
import { ObjectLoader } from "../../components/EmpluxLoaders/Loader";
import { fetchSub_Category_byName } from "../../features/SubCategory/SubCategorySlice";

const ManageCatagories = () => {
  const dispatch = useDispatch();

  // states
  const [isAddingCatagory, setAdding] = useState(false);
  const [action, setAction] = useState(null);
  const [editing, setEditing] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // selector
  const { totalPages, catagory, isLoadingCatg } = useSelector(
    (state) => state.catagory
  );
  const handleAddCatagoryClick = (flag) => {
    setAdding(flag);
  };

  // show actions
  const handleAction = (id) => {
    if (action === id) {
      setAction(null);
      return;
    }
    setAction(id);
  };

  // Adit catagory
  const handleEditing = (id) => {
    console.log(id);
    if (id >= 0) {
      setEditing(id);
    } else {
      setEditing(null);
    }
    handleAction(null);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // You can add logic here to fetch data for the new page
    const queryParams = {
      page: page - 1,
      size: 10,
    };
    dispatch(getCatagories(queryParams));
  };

  const [subCategoryName, setSearchItem] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const handleSearchChange = (e) => {
    const value = e?.target?.value;
    setSearchItem(value);
  };

  const handleSearch = (e) => {
    if (
      e.key === "Enter" &&
      subCategoryName?.trim() !== "" &&
      subCategoryName !== null
    ) {
      // dispatch(fetchSub_Category_byName(subCategoryName));
      dispatch(fetchSub_Category_byName(subCategoryName)).then((action) => {
        if (action.payload) {
          setSearchResults(action.payload); // Update with search results
        }
      });
    }
  }

  // visibility change
  const [changingVisibility, setChangingVisibility] = useState(null);
  const [isAskingCode, setAskingCode] = useState(null);
  const [catagoryName, setCatagoryName] = useState("");
  const [catagoryHidden, setCatagoryHidden] = useState(false);

  const handleConfirm = (confirmation = false) => {
    if (confirmation) {
      setAskingCode(changingVisibility);
    }
    setChangingVisibility(null);
    setAction(null);
    setCatagoryName("");
  };

  const handleVisibility = async (confirmation = "") => {
    if (confirmation === "correct") {
      await dispatch(
        changeVisibility({ id: isAskingCode, page: currentPage - 1 })
      );
    } else if (confirmation !== "close") {
      alert("Unauthorized!");
    }
    setAskingCode(null);
  };

  useEffect(() => {
    dispatch(getCatagories());
    window.scrollTo(0, 0);
  }, []);

  

  return (
    <div className="relative w-full h-full bg-white mb-10 min-h-screen">
      {/* popus */}
      {/* Add & edit Catagory  */}
      {(isAddingCatagory || editing !== null) && (
        <AddCatagory
          handleAdding={() => handleAddCatagoryClick(false)}
          edit={editing !== null ? catagory[editing] : null}
          handleEditing={handleEditing}
        />
      )}

      {/* Visibility popup */}
      {changingVisibility && (
        <Confirm
          question={
            <span className="lowercase">
              {"Are you sure to "} <strong>{catagoryHidden}</strong>
              {` Catagory ${catagoryName}?`}
            </span>
          }
          handleConfirmation={handleConfirm}
        />
      )}
      {/* code popup */}
      {isAskingCode && (
        <GetCode
          question={"Enter authentication code."}
          handleCode={handleVisibility}
        />
      )}
      {/* Search and add */}
      <div className="w-full px-4 relative flex justify-start items-center pb-4 pt-8 m-auto ">
        <input
          type="search"
          onChange={handleSearchChange}
          onKeyDown={handleSearch}
          className="p-2 my-4 mx-2 rounded-md outline-none border"
          placeholder="Search "
        />
        <button
          className="text-white p-2 absolute right-6  rounded-sm bg-green-500 font-semibold"
          onClick={() => handleAddCatagoryClick(true)}>
          Add New
        </button>
      </div>
      {/* data */}
      <div className=" w-full px-4 m-auto min-h-max ">
        {/* Head of the table */}
        <div className="h-10 border-x border-t  shadow-md bg-primary-70 text-white  flex items-center  mb-3 overflow-hidden rounded-md">
        {searchResults?.length > 0 ? (
    <>
      {/* Headers for Search Results */}
      <span className="w-1/12  h-full text-left pl-2 py-2 overflow-hidden">
            SL. No.
          </span>
          <span className="w-2/12  h-full text-left py-2">Created</span>
          <span className="w-2/12  h-full text-left py-2">Modified</span>
          <span className="w-2/12  h-full text-left py-2">Name</span>
          <span className="w-1/12  h-full text-left py-2">Rating</span>
          <span className="w-2/12  h-full text-left py-2">Review</span>
          <span className="w-1/12  h-full text-left py-2">Icon</span>
          <span className="w-1/12  h-full text-center py-2"></span>
    </>
  ) : (
    <>
      {/* Headers for Default Category List */}
      <span className="w-1/12 h-full text-left pl-2 py-2 overflow-hidden">
        SL. No.
      </span>
      <span className="w-2/12 h-full text-left py-2">Created</span>
      <span className="w-2/12 h-full text-left py-2">Updated</span>
      <span className="w-2/12 h-full text-left py-2">Category Name</span>
      <span className="w-1/12 h-full text-left py-2">Visible</span>
      <span className="w-2/12 h-full text-left py-2">Category Type</span>
      <span className="w-1/12 h-full text-left py-2">Icon</span>
      <span className="w-1/12 h-full text-center py-2">Actions</span>
    </>
  )}
        </div>
        {/* Catagories card*/}
        {/* {!isLoadingCatg  ? (
          searchResults.length > 0 ? 
          (catagory?.map(
            (
              {
                id,
                categoryType,
                categoryName,
                categoryUrl,
                showCategory,
                createdDateTime,
                updatedDataTime,
              },
              index
            ) => {
              return (
                <div
                  className={
                    " h-12 w-full hover:border-gray-400 flex items-center border-x  rounded-md relative " +
                    (index % 2 == 0 && " bg-gray-200")
                  }
                  key={index}>
                  <div className="w-1/12  h-full flex items-center text-left py-2 pl-4">
                    {id}
                  </div>
                  <div className="w-2/12  h-full flex items-center text-left py-2">
                    {createdDateTime?.slice(0, 10).replaceAll("-", "/")}
                  </div>
                  <div className="w-2/12  h-full flex items-center text-left py-2">
                    {updatedDataTime?.slice(0, 10).replaceAll("-", "/")}
                  </div>
                  <div className="w-2/12  h-full flex items-center text-left py-2">
                    <h1>{categoryName}</h1>
                  </div>
                  <div className="w-1/12  h-full flex items-center text-left py-2">
                    {showCategory ? (
                      <span className="float-right mr-10 px-2 text-sm   rounded-md text-white bg-green-400">
                        Yes
                      </span>
                    ) : (
                      <span className="float-right mr-10 px-2 text-sm rounded-md text-white bg-red-400">
                        No
                      </span>
                    )}
                  </div>
                  <div className="w-2/12  h-full flex items-center text-left py-2">
                    <h1>{categoryType}</h1>
                  </div>
                  <div className="w-1/12  h-full flex items-center text-left py-2">
                    <h1>
                      <img
                        src={categoryUrl}
                        alt={categoryName}
                        className="h-8 w-8 object-fill"
                      />
                    </h1>
                  </div>
                  <span
                    className="w-1/12  h-full flex justify-center py-2 hover:text-xl cursor-pointer"
                    onClick={() => handleAction(id)}>
                    <BsThreeDotsVertical />
                  </span>
                  {action === id && (
                    <div className="w-[150px]  h-[120px] bg-white absolute right-0 top-9  z-10   rounded-b-md shadow-lg flex flex-col  justify-center text-[14px] overflow-hidden pt-1">
                      <div
                        className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer"
                        onClick={() => handleEditing(index)}>
                        <span>
                          <AiFillEdit />
                        </span>
                        <span>Edit</span>
                      </div>
                      <div
                        className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer"
                        onClick={() => {
                          setChangingVisibility(id);
                          setCatagoryName(categoryName);
                          setCatagoryHidden(showCategory ? "Hide" : "Show");
                        }}>
                        <span>
                          <AiTwotoneEyeInvisible />
                        </span>
                        <span>Visibility</span>
                      </div>
                      <Link
                        to={`/catagories/sub-category/${id}`}
                        className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer">
                        <span>
                          <BsHddStackFill />
                        </span>
                        <span>Sub catagory</span>
                      </Link>
                    </div>
                  )}
                </div>
              );
            }
          )) : ( searchResults?.map(
            (
              {
                id,
                categoryType,
                categoryName,
                categoryUrl,
                showCategory,
                createdDateTime,
                updatedDataTime,
              },
              index
            ) => {
              return (
                <div
                  className={
                    " h-9 w-full hover:border-gray-400 flex items-center border-x  rounded-md relative " +
                    (index % 2 == 0 && " bg-gray-200")
                  }
                  key={index}>
                  <div className="w-1/12  h-full flex items-center text-left py-2 pl-4">
                    {id}
                  </div>
                  <div className="w-2/12  h-full flex items-center text-left py-2">
                    {createdDateTime?.slice(0, 10).replaceAll("-", "/")}
                  </div>
                  <div className="w-2/12  h-full flex items-center text-left py-2">
                    {updatedDataTime?.slice(0, 10).replaceAll("-", "/")}
                  </div>
                  <div className="w-2/12  h-full flex items-center text-left py-2">
                    <h1>{categoryName}</h1>
                  </div>
                  <div className="w-1/12  h-full flex items-center text-left py-2">
                    {showCategory ? (
                      <span className="float-right mr-10 px-2 text-sm   rounded-md text-white bg-green-400">
                        Yes
                      </span>
                    ) : (
                      <span className="float-right mr-10 px-2 text-sm rounded-md text-white bg-red-400">
                        No
                      </span>
                    )}
                  </div>
                  <div className="w-2/12  h-full flex items-center text-left py-2">
                    <h1>{categoryType}</h1>
                  </div>
                  <div className="w-1/12  h-full flex items-center text-left py-2">
                    <h1>
                      <img
                        src={categoryUrl}
                        alt={categoryName}
                        className="h-6 w-6 object-fill"
                      />
                    </h1>
                  </div>
                  <span
                    className="w-1/12  h-full flex justify-center py-2 hover:text-xl cursor-pointer"
                    onClick={() => handleAction(id)}>
                    <BsThreeDotsVertical />
                  </span>
                  {action === id && (
                    <div className="w-[150px]  h-[120px] bg-white absolute right-0 top-9  z-10   rounded-b-md shadow-lg flex flex-col  justify-center text-[14px] overflow-hidden pt-1">
                      <div
                        className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer"
                        onClick={() => handleEditing(index)}>
                        <span>
                          <AiFillEdit />
                        </span>
                        <span>Edit</span>
                      </div>
                      <div
                        className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer"
                        onClick={() => {
                          setChangingVisibility(id);
                          setCatagoryName(categoryName);
                          setCatagoryHidden(showCategory ? "Hide" : "Show");
                        }}>
                        <span>
                          <AiTwotoneEyeInvisible />
                        </span>
                        <span>Visibility</span>
                      </div>
                      <Link
                        to={`/catagories/sub-category/${id}`}
                        className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer">
                        <span>
                          <BsHddStackFill />
                        </span>
                        <span>Sub catagory</span>
                      </Link>
                    </div>
                  )}
                </div>
              );
            }
          ) )
        ) : (
          <div className="w-full h-[80vh]">
            <ObjectLoader />
          </div>
        )} */}
{
  !isLoadingCatg ? (
    <div className="w-full">
      {(searchResults?.length > 0 ? searchResults : catagory)?.map(
        (
          item,
          index
        ) => {
          // Handle the dynamic structure of the data
          const isSearchResult = searchResults?.length > 0;

          const {
            id,
            name,
            rating,
            review,
            iconUrls,
            thumbnailUrls,
            smallBannerM,
            description,
            createdDateTime,
            updatedDataTime,
            title,
            imageIUrlA,
            categoryType,
            categoryName,
            categoryUrl,
            showCategory
          } = item;

          return (
            <div
              className={
                " h-9 w-full hover:border-gray-400 flex items-center border-x rounded-md relative " +
                (index % 2 === 0 && " bg-gray-200")
              }
              key={index}>
              {/* ID */}
              <div className="w-1/12 h-full flex items-center text-left py-2 pl-4">
                {id}
              </div>

              {/* Created Date */}
              <div className="w-2/12 h-full flex items-center text-left py-2">
                {createdDateTime?.slice(0, 10).replaceAll("-", "/")}
              </div>

              {/* Updated Date */}
              <div className="w-2/12 h-full flex items-center text-left py-2">
                {updatedDataTime?.slice(0, 10).replaceAll("-", "/")}
              </div>

              {/* Name or Category Name */}
              <div className="w-2/12 h-full flex items-center text-left py-2">
                <h1>{isSearchResult ? name : categoryName}</h1>
              </div>

              {/* Show Category or Rating */}
              <div className="w-1/12 h-full flex items-center text-left py-2">
                {isSearchResult ? (
                  <span>{rating}</span>
                ) : (
                  <span
                    className={`float-right mr-10 px-2 text-sm rounded-md text-white ${
                      showCategory ? "bg-green-400" : "bg-red-400"
                    }`}>
                    {showCategory ? "Yes" : "No"}
                  </span>
                )}
              </div>

              {/* Type or Reviews */}
              <div className="w-2/12 h-full flex items-center text-left py-2">
                <h1>{isSearchResult ? review : categoryType}</h1>
              </div>

              {/* Icon or Category Image */}
              <div className="w-1/12 h-full flex items-center text-left py-2">
                <img
                  src={isSearchResult ? iconUrls || thumbnailUrls : categoryUrl}
                  alt={isSearchResult ? name : categoryName}
                  className="h-6 w-6 object-fill"
                />
              </div>

              {/* Action Button */}
              <span
                className="w-1/12 h-full flex justify-center py-2 hover:text-xl cursor-pointer"
                onClick={() => handleAction(id)}>
                <BsThreeDotsVertical />
              </span>

              {/* Actions Popup */}
              {action === id && (
                <div className="w-[150px] h-[120px] bg-white absolute right-0 top-9 z-10 rounded-b-md shadow-lg flex flex-col justify-center text-[14px] overflow-hidden pt-1">
                  <div
                    className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer"
                    onClick={() => handleEditing(index)}>
                    <span>
                      <AiFillEdit />
                    </span>
                    <span>Edit</span>
                  </div>
                  <div
                    className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer"
                    onClick={() => {
                      setChangingVisibility(id);
                      setCatagoryName(isSearchResult ? name : categoryName);
                      setCatagoryHidden(showCategory ? "Hide" : "Show");
                    }}>
                    <span>
                      <AiTwotoneEyeInvisible />
                    </span>
                    <span>Visibility</span>
                  </div>
                  <Link
                    to={`/catagories/sub-category/${id}`}
                    className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer">
                    <span>
                      <BsHddStackFill />
                    </span>
                    <span>Sub category</span>
                  </Link>
                </div>
              )}
            </div>
          );
        }
      )}
    </div>
  ) : (
    <div className="w-full h-[80vh]">
      <ObjectLoader />
    </div>
  )
}



        {/* Add New */}
        <div
          className="w-full mt-4 border-2 border-dashed flex  items-center justify-center h-16 gap-4 text-2xl font-semibold text-gray-400 hover:bg-gray-50 cursor-pointer"
          onClick={() => handleAddCatagoryClick(true)}>
          <span>
            <IoAdd />
          </span>
          <span>Add More Catagories</span>
        </div>
      </div>
      <PaginationBar
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default ManageCatagories;
