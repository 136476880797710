import React from "react";

import Services from "../../Images/Icons/grommet-icons_services.svg";
import Guide from "../../Images/Icons/ri_guide-line.svg";
import Offer from "../../Images/Icons/bxs_offer.svg";
import FAQ from "../../Images/Icons/mdi_faq.svg";
import Partner from "../../Images/Icons/Vector.svg";
import Payment from "../../Images/Icons/fluent_payment-24-regular.svg";
import { useNavigate } from "react-router-dom";

const Help = () => {
	const navigate = useNavigate();
	const handleFAQNavigate = (path) => {
		navigate(`${path}`);
	};

	return (
		<div className=" flex justify-center items-center  h-screen">
			
			<div className="  flex flex-col w-full bg-[#FFFFFF] rounded-t-[10px] mr-9 h-[94%] shadow-md shadow-[#0000001A]">
				<div className=" h-[10%] bg-[#065668] flex items-center rounded-t-[10px] border border-1 border-[#065668] shadow-md">
					<h1 className=" font-roboto text-[20px] text-white pl-[50px] font-bold leading-[23.44px] tracking-[0.04em] text-left decoration-skip-none">OTHERS</h1>
				</div>
				<div className=" flex flex-col gap-16 h-[90%] justify-center items-center ">
	<div className=" flex flex-row gap-16">
		<div
			className=" w-[200px] h-[196px] bg-[#F8FAFA] cursor-pointer border-[0.5px] border-[#065668] rounded-lg flex flex-col gap-6 items-center justify-center pt-[3.688rem] pb-[2rem]"
			style={{ boxShadow: "0px 4px 4px 0px #0000004D" }}>
			<img src={Services} alt="" />
			<h1 className=" font-roboto text-[14px] font-normal leading-[16.41px]  tracking-[0.02em] text-center ">City and <br /> Locations</h1>
		</div>
		<div
			className="bg-[#F8FAFA] w-[200px] h-[196px] cursor-pointer rounded-lg border-[0.5px] border-[#065668] flex flex-col gap-6 items-center justify-center pt-[3.688rem] pb-[2rem]"
			style={{ boxShadow: "0px 4px 4px 0px #0000004D" }}
			onClick={()=> navigate("/help/testimonials")}
			>
			<img src={Guide} alt="" />
			<h1 className=" font-roboto text-[14px] font-normal leading-[16.41px]  tracking-[0.02em] text-center ">Custom <br /> Testimonials</h1>
		</div>
		<div
			className="bg-[#F8FAFA] w-[200px] h-[196px] cursor-pointer rounded-lg border-[0.5px] border-[#065668] flex flex-col gap-6 items-center justify-center pt-[3.688rem] pb-[2rem]"
			style={{ boxShadow: "0px 4px 4px 0px #0000004D" }}>
			<img src={Offer} alt="" />
			<h1 className=" font-roboto text-[14px] font-normal leading-[16.41px]  tracking-[0.02em] text-center ">Custom <br /> Notifications</h1>
		</div>
	</div>
	<div className=" flex flex-row gap-16">
		<div
			className="bg-[#F8FAFA] w-[200px] h-[196px]  rounded-lg border-[0.5px] border-[#065668] flex flex-col gap-6 items-center justify-center pt-[3.688rem] pb-[2rem] cursor-pointer"
			style={{ boxShadow: "0px 4px 4px 0px #0000004D" }}
			>
			<img src={FAQ} alt="" />
			<h1 className=" font-roboto text-[14px] font-normal leading-[16.41px]  tracking-[0.02em] text-center ">Employees</h1>
		</div>
		<div
			className="bg-[#F8FAFA] w-[200px] h-[196px] cursor-pointer rounded-lg border-[0.5px] border-[#065668] flex flex-col gap-6 items-center justify-center pt-[3.688rem] pb-[2rem]"
			style={{ boxShadow: "0px 4px 4px 0px #0000004D" }}
			onClick={() => handleFAQNavigate("/help/FAQs")}>
			<img src={Partner} alt="" />
			<h1 className=" font-roboto text-[14px] font-normal leading-[16.41px]  tracking-[0.02em] text-center ">FAQs</h1>
		</div>
		<div
			className="bg-[#F8FAFA] w-[200px] h-[196px] cursor-pointer rounded-lg border-[0.5px] border-[#065668] flex flex-col gap-6 items-center justify-center pt-[3.688rem] pb-[2rem]"
			style={{ boxShadow: "0px 4px 4px 0px #0000004D" }}>
			<img src={Payment} alt="" />
			<h1 className=" font-roboto text-[14px] font-normal leading-[16.41px]  tracking-[0.02em] text-center ">Coupons</h1>
		</div>
	</div>
</div>

			</div>
		</div>
	);
};

export default Help;
