import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { data } from "autoprefixer";

const initialState = {
  isLoadingSubCatg: false,
  SubCategories: [],
  SCpageSize: 0,
  SCcurrentPage: 0,
  SCtotalPages: 1,
};

// Get API
export const fetchSubCategory = createAsyncThunk(
  "subcategory/fetchsubcategory",
  async (categoryId, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(`api/sub/category/${categoryId}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      return resp.data;
    } catch (error) {
      if (error) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// fetch all sub category
export const fetchAllSubCategory = createAsyncThunk(
  "subcategory/fetchAllSubCategory",
  async (queryParams, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(`api/sub/category`, {
        params: { page: queryParams?.page, size: queryParams?.size },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      return resp.data;
    } catch (error) {
      if (error) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// SEarch through APIP in category
export const fetchSub_Category_byName = createAsyncThunk(
  "subcategory/fetchSubCategory",
  async (subCategoryName , thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(`api/sub/category/search/name/${subCategoryName}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      return resp.data;
    } catch (error) {
      if (error) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);



// Post API
export const postSubCategory = createAsyncThunk(
  "subcategory/postsubcategory",
  async ({ categoryId, data }, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.post(
        `api/sub/category/${categoryId}`,
        data,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// PUT API
export const updateSubCategory = createAsyncThunk(
  "subCategory/updatesuncategory",
  async ({ subCatId, data }, thunkAPI) => {
    console.log(data);
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.put(`api/sub/category/${subCatId}`, data, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      return resp.data;
    } catch (error) {
      if (error) {
        alert("Error Occured While Updating Sub-Category!");
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
const SubCatagorySlice = createSlice({
  name: "subcategory",
  initialState,
  reducers: {
    setLoadingSubCatg: (state) => {
      state.isLoadingSubCatg = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubCategory.pending, (state) => {
        state.isLoadingSubCatg = true;
      })
      .addCase(fetchSubCategory.fulfilled, (state, { payload }) => {
        state.isLoadingSubCatg = false;
        // console.log(payload.content[0].subCategory);
        state.SubCategories = payload.content[0]?.subCategory;
      })
      .addCase(fetchSub_Category_byName.fulfilled, (state, { payload }) => {
        state.isLoadingSubCatg = false;
        // console.log(payload.content[0].subCategory);
        state.SubCategories = payload;
      })
      .addCase(fetchSubCategory.rejected, (state) => {
        state.isLoadingSubCatg = false;
      })
      .addCase(postSubCategory.pending, (state) => {
        state.isLoadingSubCatg = true;
      })
      .addCase(postSubCategory.fulfilled, (state, { payload }) => {
        alert("SubCategory Added Successfully!");
        state.isLoadingSubCatg = false;
      })
      .addCase(postSubCategory.rejected, (state) => {
        state.isLoadingSubCatg = false;
      })
      .addCase(updateSubCategory.pending, (state) => {
        state.isLoadingSubCatg = true;
      })
      .addCase(updateSubCategory.fulfilled, (state, { payload }) => {
        alert("SubCategory Updated Successfully!");
        state.isLoadingSubCatg = false;
      })
      .addCase(updateSubCategory.rejected, (state) => {
        state.isLoadingSubCatg = false;
      })

      .addCase(fetchAllSubCategory.pending, (state) => {
        state.isLoadingSubCatg = true;
      })
      .addCase(fetchAllSubCategory.fulfilled, (state, { payload }) => {
        state.isLoadingSubCatg = false;
        state.SCcurrentPage = payload?.currentPage;
        state.SCpageSize = payload?.pageSize;
        state.SCtotalPages = payload?.totalPages;
        state.SubCategories = payload.content;
      })
      .addCase(fetchAllSubCategory.rejected, (state) => {
        state.isLoadingSubCatg = false;
      });
  },
});
export default SubCatagorySlice.reducer;
export const { setLoadingSubCatg } = SubCatagorySlice.actions;
