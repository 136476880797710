import React, { useEffect } from "react";
import { useState } from "react";
import { IoAdd } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { BsThreeDotsVertical, BsHddStackFill } from "react-icons/bs";
import { AiFillEdit, AiTwotoneEyeInvisible } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import {
  fetchAllSubCategory,
  fetchSub_Category_byName,
  fetchSubCategory,
} from "../../features/SubCategory/SubCategorySlice";
import AddSubCategory from "../../components/AddSubCategory";
// import EditSubCategory from "../../components/EditSubCategory";
import PaginationBar from "../../components/PaginationBar";

const SubCategory = () => {
  const dispatch = useDispatch();
  const { categoryId } = useParams();

  const [isAddingCatagory, setAdding] = useState(false);
  const [action, setAction] = useState(null);
  const [editing, setEditing] = useState(null);
  // const [subCategoryName, setSearchItem] = useState(null);
  // const [searchResults, setSearchResults] = useState([]);

  const { SubCategories, SCtotalPages } = useSelector(
    (state) => state.subcategory
  );
  const { catagory } = useSelector((state) => state.catagory);
  const [currentPage, setCurrentPage] = useState(1);
  // console.log(SubCategories);

  const handleAddCatagoryClick = (flag) => {
    setAdding(flag);
  };
  const handleAction = (id) => {
    if (action === id) {
      setAction(null);
      return;
    }
    setAction(id);
  };
  const handleEditing = (index) => {
    if (index >= 0) {
      setEditing(index);
    } else {
      setEditing(null);
    }

    handleAction(null);
  };

  // const handleSearchChange = (e) => {
  //   const value = e?.target?.value;
  //   setSearchItem(value);
  // };

  // const handleSearch = (e) => {
  //   if (
  //     e.key === "Enter" &&
  //     subCategoryName?.trim() !== "" &&
  //     subCategoryName !== null
  //   ) {
  //     dispatch(fetchSub_Category_byName(subCategoryName));
  //   }
  // }
  // };
  // const handleSearch = (e) => {
  //   if (
  //     e.key === "Enter" &&
  //     subCategoryName?.trim() !== "" &&
  //     subCategoryName !== null
  //   ) {
  //     dispatch(fetchSub_Category_byName(subCategoryName)).then((action) => {
  //       if (action.payload) {
  //         setSearchResults(action.payload); // Update with search results
  //       }
  //     });
  //   }
  // };
  

  

  

  const handlePageChange = (page) => {
    const queryParams = {
      page: page - 1,
      size: 20,
    };
    setCurrentPage(page);
    dispatch(fetchAllSubCategory(queryParams));
  };


  
  useEffect(() => {
    if (categoryId ) {
      dispatch(fetchSubCategory(categoryId));
    } else {
      const queryParams = {
        page: 0,
        size: 20,
      };
      dispatch(fetchAllSubCategory(queryParams));
    }
  }, []);


  




  return (
    <div className="relative w-full h-full bg-white mb-10 min-h-screen">
      {/* popus */}
      {/* Add Catagory  */}
      {(isAddingCatagory || editing != null) && (
        <AddSubCategory
          handleAdding={handleAddCatagoryClick}
          CatgId={categoryId}
          edit={editing != null ? SubCategories[editing] : null}
          handleEditing={handleEditing}
        />
      )}
      {/* Edit Catagory */}

      {/* Search and add */}
      <h1 className="m-auto w-max text-4xl uppercase font-mono font-semibold mt-8">
        {"SubCategories" +
          (categoryId
            ? "-" + catagory.find(({ id }) => id == categoryId)?.categoryName
            : "")
            }
      </h1>
      <div className="w-full px-4  relative flex justify-start items-center pb-4 pt-2 m-auto ">
        <input
          type="text"
          // onChange={handleSearchChange}
          // onKeyDown={handleSearch}
          className="p-2 my-4 mx-2 rounded-md outline-none bg-[#f3f9c4]"
          placeholder="Search "
        />
        {categoryId && (
          <button
            className="text-white p-2 absolute right-6  rounded-sm bg-green-500 font-semibold"
            onClick={() => handleAddCatagoryClick(true)}>
            Add New
          </button>
        )}
      </div>
      {/* data */}
      <div className=" w-full px-4 m-auto min-h-max ">
        {/* Head of the table */}
        <div className="h-10 border-x border-t  shadow-md bg-primary-70 text-white  flex items-center  mb-3 overflow-hidden rounded-md">
          <span className="w-1/12  h-full text-left pl-2 py-2 overflow-hidden">
            SL. No.
          </span>
          <span className="w-2/12  h-full text-left py-2">Created</span>
          <span className="w-2/12  h-full text-left py-2">Modified</span>
          <span className="w-2/12  h-full text-left py-2">Name</span>
          <span className="w-1/12  h-full text-left py-2">Rating</span>
          <span className="w-2/12  h-full text-left py-2">Review</span>
          <span className="w-1/12  h-full text-left py-2">Icon</span>
          <span className="w-1/12  h-full text-center py-2"></span>
        </div>



        {/* Catagories card*/}
        {SubCategories?.map((element, index) => {
          const {
            id,
            name,
            rating,
            review,
            createdDateTime,
            updatedDataTime,
            iconUrls,
            thumbnailUrl,
          } = element;

          return (
            <div
              className={
                " h-12 w-full hover:border-gray-400 flex items-center border-x  rounded-md relative " +
                (index % 2 == 0 && " bg-gray-200")
              }
              key={index}>
              <div className="w-1/12  h-full flex items-center text-left py-2 pl-4">
                {id}
              </div>
              <div className="w-2/12  h-full flex items-center text-left py-2">
                {createdDateTime?.slice(0, 10).replaceAll("-", "/")}
              </div>
              <div className="w-2/12  h-full flex items-center text-left py-2">
                {updatedDataTime?.slice(0, 10).replaceAll("-", "/")}
              </div>
              <div className="w-2/12  h-full flex items-center text-left py-2">
                <h1>{name}</h1>
              </div>
              <div className="w-1/12  h-full flex items-center text-left py-2">
                <span className="float-right mr-10 px-2 text-sm   rounded-md text-white bg-green-400">
                  {rating}
                </span>
              </div>
              <div className="w-2/12  h-full flex items-center text-left py-2">
                <h1>{review}</h1>
              </div>
              <div className="w-1/12  h-full flex items-center text-left py-2">
                <h1>
                  <img
                    src={iconUrls}
                    alt={name}
                    className="h-8 w-8 object-fill"
                  />
                </h1>
              </div>
              <span
                className="w-1/12  h-full flex justify-center py-2 hover:text-xl cursor-pointer"
                onClick={() => handleAction(id)}>
                <BsThreeDotsVertical />
              </span>
              {/* Actions Popup */}
              {action === id && (
                <div className="w-[150px]  h-[120px] bg-white absolute right-0 top-9  z-10   rounded-b-md shadow-lg flex flex-col  justify-center text-[14px] overflow-hidden pt-1">
                  <div
                    className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer"
                    onClick={() => handleEditing(index)}>
                    <span>
                      <AiFillEdit />
                    </span>
                    <span>Edit</span>
                  </div>
                  <div className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer">
                    <span>
                      <AiTwotoneEyeInvisible />
                    </span>
                    <span>Visibility</span>
                  </div>
                  <Link
                    to={`/catagories/sub-category/issue/${id}`}
                    className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer">
                    <span>
                      <BsHddStackFill />
                    </span>
                    <span>Issues</span>
                  </Link>
                </div>
              )}
            </div>
          );
        })}


        {/* {subCategoryName?.map((element, index) => {
          const {
            id,
            name,
            rating,
            review,
            createdDateTime,
            updatedDataTime,
            iconUrls,
            thumbnailUrl,
          } = element;

          return (
            <div
              className={
                " h-9 w-full hover:border-gray-400 flex items-center border-x  rounded-md relative " +
                (index % 2 == 0 && " bg-gray-200")
              }
              key={index}>
              <div className="w-1/12  h-full flex items-center text-left py-2 pl-4">
                {id}
              </div>
              <div className="w-2/12  h-full flex items-center text-left py-2">
                {createdDateTime?.slice(0, 10).replaceAll("-", "/")}
              </div>
              <div className="w-2/12  h-full flex items-center text-left py-2">
                {updatedDataTime?.slice(0, 10).replaceAll("-", "/")}
              </div>
              <div className="w-2/12  h-full flex items-center text-left py-2">
                <h1>{name}</h1>
              </div>
              <div className="w-1/12  h-full flex items-center text-left py-2">
                <span className="float-right mr-10 px-2 text-sm   rounded-md text-white bg-green-400">
                  {rating}
                </span>
              </div>
              <div className="w-2/12  h-full flex items-center text-left py-2">
                <h1>{review}</h1>
              </div>
              <div className="w-1/12  h-full flex items-center text-left py-2">
                <h1>
                  <img
                    src={iconUrls}
                    alt={name}
                    className="h-6 w-6 object-fill"
                  />
                </h1>
              </div>
              <span
                className="w-1/12  h-full flex justify-center py-2 hover:text-xl cursor-pointer"
                onClick={() => handleAction(id)}>
                <BsThreeDotsVertical />
              </span>
              {action === id && (
                <div className="w-[150px]  h-[120px] bg-white absolute right-0 top-9  z-10   rounded-b-md shadow-lg flex flex-col  justify-center text-[14px] overflow-hidden pt-1">
                  <div
                    className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer"
                    onClick={() => handleEditing(index)}>
                    <span>
                      <AiFillEdit />
                    </span>
                    <span>Edit</span>
                  </div>
                  <div className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer">
                    <span>
                      <AiTwotoneEyeInvisible />
                    </span>
                    <span>Visibility</span>
                  </div>
                  <Link
                    to={`/catagories/sub-category/issue/${id}`}
                    className="flex gap-2 hover:bg-gray-50 p-2 rounded-md font-mono text-gray-600 items-center cursor-pointer">
                    <span>
                      <BsHddStackFill />
                    </span>
                    <span>Issues</span>
                  </Link>
                </div>
              )}
            </div>
          );
        })} */}



        {/* Add New */}
        <div
          className="w-full mt-4 border-2 border-dashed flex  items-center justify-center h-16 gap-4 text-2xl font-semibold text-gray-400 hover:bg-gray-50 cursor-pointer"
          onClick={() => handleAddCatagoryClick(true)}>
          <span>
            <IoAdd />
          </span>
          <span>Add More Catagories</span>
        </div>
      </div>
      <PaginationBar
        currentPage={currentPage}
        totalPages={SCtotalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default SubCategory;
