import React from "react";
import { HiDotsVertical } from "react-icons/hi";
import { BiSolidEdit } from "react-icons/bi";
import { BiSolidHide } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
import { BsFillCalendarFill } from "react-icons/bs";
import { useState } from "react";
import AddNewSubSection from "./AddNewSubSection";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchSubHome,
  hideHomeSection,
  showHomeSection,
} from "../features/Home/homeSlice";
import EditSubSectionHomeLayout from "./EditSubSectionHomeLayout";
import EditSectionHomeLayout from "./EditSectionHomeLayout";
import { BiSolidShow } from "react-icons/bi";
import { FaEyeSlash } from "react-icons/fa";
import Confirm from "./Confirm";
import GetCode from "./GetCode";
import { ObjectLoader } from "./EmpluxLoaders/Loader";

const FSDropdown = ({ homeId, isVisible }) => {
  const { homeSubSection, homeData, isLoadingHome } = useSelector(
    (store) => store.home
  );

  const [changingVisibility, setChangingVisibility] = useState(null);
  const [isAskingCode, setAskingCode] = useState(null);
  const [catagoryName, setCatagoryName] = useState("");
  const [catagoryHidden, setCatagoryHidden] = useState(false);

  const handleConfirm = (confirmation = false) => {
    if (confirmation) {
      setAskingCode(changingVisibility);
    }
    setChangingVisibility(null);
    // setAction(null);
    setCatagoryName("");
  };

  console.log(homeSubSection);
  const handleVisibility = async (confirmation = "") => {
    if (confirmation === "correct" && isVisible) {
      await dispatch(hideHomeSection(homeId));
    } else if (confirmation === "correct" && !isVisible) {
      await dispatch(showHomeSection(homeId));
    } else if (confirmation !== "close") {
      alert("Unauthorized!");
    }
    setAskingCode(null);
  };

  const dispatch = useDispatch();

  const [isdot, setDot] = useState(false);
  const handleDot = (index) => {
    isdot === index ? setDot(null) : setDot(index);
  };

  const [isAddNewSubSection, setAddNewSubSection] = useState(false);
  const handleAddNewSubSection = () => {
    setAddNewSubSection(!isAddNewSubSection);
  };
  const [isSubSectionEdit, setSubSectionEdit] = useState(false);
  const handleEditSubSection = (subSectionId) => {
    if (!isSubSectionEdit && subSectionId) setSubSectionEdit(subSectionId);
    else setSubSectionEdit(null);
  };

  const [editSection, setEditSection] = useState(null);
  const handleSectionEdit = (id) => {
    if (!editSection && id) {
      setEditSection(id);
    } else {
      setEditSection(null);
    }
  };

  useEffect(() => {
    dispatch(fetchSubHome(homeId));
  }, []);
  return (
    <div>
      {/* add new sub section popup  */}
      {isAddNewSubSection && (
        <AddNewSubSection
          handleAdding={handleAddNewSubSection}
          homeId={homeId}
        />
      )}
      {/* sub-section edit section popup  */}
      {isSubSectionEdit && (
        <EditSubSectionHomeLayout
          handleAdding={handleEditSubSection}
          editId={isSubSectionEdit}
          homeId={homeId}
          subsectionData={homeSubSection}
        />
      )}

      {/* edit popup  */}
      {editSection && (
        <EditSectionHomeLayout
          handleAdding={handleSectionEdit}
          homeLayoutEdit={homeData?.find((elem) => elem?.id === editSection)}
          editId={editSection}
        />
      )}

      {/* Visibility popup */}
      {changingVisibility && (
        <Confirm
          question={
            <span className="lowercase">
              {"Are you sure to "} <strong>{catagoryHidden}</strong>
              {` Catagory ${catagoryName}?`}
            </span>
          }
          handleConfirmation={handleConfirm}
        />
      )}
      {/* code popup */}
      {isAskingCode && (
        <GetCode
          question={"Enter authentication code."}
          handleCode={handleVisibility}
        />
      )}

      <div className="border mx-3 flex flex-wrap">
        {homeSubSection.length > 0 && !isLoadingHome ? (
          homeSubSection?.map((elem, index) => {
            return (
              <div
                className="relative flex flex-wrap border p-3 justify-between min-w-[10rem] w-[20rem] gap-2"
                key={index}>
                <div className="flex justify-center items-center w-full">
                  <img src={elem?.thumbnailUrl} alt="" className="w-full" />
                </div>

                <div className="flex justify-center items-center  text-lg font-medium">
                  {elem?.title}
                </div>

                {/* handle the three dots functionality  */}
                <div
                  className="flex justify-end  items-center relative "
                  key={index}>
                  <HiDotsVertical
                    className="text-xl cursor-pointer"
                    onClick={() => handleDot(index)}
                  />

                  <div
                    className={` ${
                      isdot === index
                        ? "absolute h-0 w-0 border-t-[10px] right-[30px] top-[70px] border-t-white border-l-[10px] border-l-transparent border-r-transparent  border-r-[10px]  rotate-180 z-40 "
                        : "hidden"
                    }`}></div>
                  <div
                    className={` ${
                      isdot === index
                        ? "absolute flex flex-col justify-center right-0 top-[100%] h-32 w-[120px] bg-white rounded-md z-30"
                        : "hidden"
                    }`}
                    style={{ boxShadow: "0px 2px 20px 4px rgba(0,0,0,0.2)" }}>
                    <div
                      className="flex flex-row text-lg gap-3 justify-start items-center mx-2 my-1  font-mono cursor-pointer"
                      onClick={() => handleEditSubSection(elem?.id)}>
                      <BiSolidEdit className="text-2xl" />
                      Edit
                    </div>
                    <div className="flex flex-row text-lg gap-3 justify-start items-center mx-2 my-2 font-mono">
                      <BiSolidHide className="text-2xl " />
                      Hide
                    </div>
                    <div className="flex flex-row text-lg gap-3 justify-start items-center mx-2 my-1 font-mono">
                      <BsFillCalendarFill className="text-lg ml-1" />
                      Details
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-gray-500 h-20 flex w-full justify-center items-center p-5">
            {isLoadingHome ? <ObjectLoader /> : "No Subsection Found"}
          </div>
        )}
      </div>

      {/* Add new sub section  */}
      <div>
        <div className="flex flex-row mx-3  my-3 gap-4">
          <div
            className="flex items-center justify-center gap-2 text-xl border-dashed border-2 border-black w-5/6 capitalize text-gray-400 cursor-pointer"
            onClick={handleAddNewSubSection}>
            <AiOutlinePlus className="text-xl font-thin" /> Add new sub section
          </div>
          {isVisible ? (
            <div
              className="flex flex-col justify-center items-center border w-1/12 font-semibold bg-primary text-white bg-black px-y cursor-pointer text-sm"
              onClick={() => {
                // dispatch(hideHomeSection(homeId))
                setCatagoryHidden("Hide");
                setChangingVisibility(homeId);
              }}>
              <FaEyeSlash className="text-xl" />
              Hide
            </div>
          ) : (
            <div
              className="flex flex-col justify-center items-center border w-1/12 font-semibold bg-primary text-black bg-white px-y cursor-pointer text-sm"
              onClick={() => {
                // dispatch(showHomeSection(homeId))
                setCatagoryHidden("Show");
                setChangingVisibility(homeId);
              }}>
              <BiSolidShow className="text-xl" />
              Show
            </div>
          )}
          <div
            className="flex justify-center items-center border w-1/6 font-semibold bg-[#EEBF00] text-white text-lg uppercase cursor-pointer"
            onClick={() => handleSectionEdit(homeId)}>
            Edit
          </div>
        </div>
      </div>
    </div>
  );
};

export default FSDropdown;
