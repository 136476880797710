import React, { useEffect, useState } from "react";
import trash from "../../Images/trashLogo.svg";
import edit from "../../Images/editLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { deleteTestimonials, getTestimonials, postTestimonials, updateTestimonials } from "../../features/Testimonials/TestiMonialsSlice";

const ManageTestimonials = () => {
  const dispatch = useDispatch();

  // Fetch testimonials from Redux
  const { testimonials = [], isLoadingTesimonials = false } = useSelector(
    (state) => state.testimonials || {}
  );

  const [showForm, setShowForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Tracks if we're in edit mode
  const [formData, setFormData] = useState({
    id: 0,
    reviewText: "",
    userName: "",
    rating: "",
    image: "",
    bookingSubcategory: "",
    address: "",
  });

  useEffect(() => {
    // Dispatch to fetch testimonials on component mount
    dispatch(getTestimonials());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddTestimonial = async (e) => {
    e.preventDefault();
    try {
      await dispatch(postTestimonials(formData)).unwrap();
      console.log("Testimonial posted successfully");
      setShowForm(false);
    } catch (error) {
      console.error("Error posting testimonial:", error);
    }
  };

  const handleEditTestimonial = async (e) => {
    e.preventDefault();
  
    const dataToSend = {
      testimonialId: formData.id, // Use the testimonial ID from formData
      contentData: {
        reviewText: formData.reviewText,
        userName: formData.userName,
        rating: formData.rating,
        image: formData.image,
        bookingSubcategory: formData.bookingSubcategory,
        address: formData.address,
      },
    };
  
    try {
      await dispatch(updateTestimonials(dataToSend)).unwrap();
      console.log("Testimonial updated successfully");
      setShowForm(false); // Close the form
      setIsEditing(false); // Exit editing mode
    } catch (error) {
      console.error("Error updating testimonial:", error);
    }
  };
  

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteTestimonials(id));
      console.log("Testimonial deleted successfully");
    } catch (error) {
      console.error("Error deleting testimonial:", error);
    }
  };

  const openEditForm = (testimonial) => {
    setFormData(testimonial); // Populate the form with the testimonial data
    setShowForm(true);
    setIsEditing(true);
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col w-full bg-white rounded-t-[10px] mr-[16px] h-[94%] shadow-md shadow-[#0000001A]">
        <div className="h-[10%] bg-[#065668] flex items-center rounded-t-[10px] border border-1 border-[#065668] shadow-md">
          <h1 className="font-roboto text-[20px] text-white pl-[30px] font-bold leading-[23.44px] tracking-[0.04em]">
            CUSTOM TESTIMONIALS
          </h1>
        </div>
        <div
          className={`grid grid-cols-2 ${
            testimonials.length > 4 ? `grid-rows-${Math.ceil(testimonials.length * 4)}` : "grid-rows-4"
          } gap-8 px-7 py-4 h-[90%] overflow-y-scroll`}
        >
          {testimonials?.map((testimonial) => (
            <div
              key={testimonial.id}
              className="w-[630px] h-[135px] bg-[#F8FAFA] border rounded-md border-1 border-[#065668] shadow-md shadow-[#0000004D] flex items-center justify-center"
            >
              <div className="flex flex-col w-full">
                <div className="flex flex-row w-full justify-between items-center px-4 pb-[10px]">
                  <div className="flex flex-row justify-between w-full items-center gap-4">
                    <img src="" alt="" className="bg-[#D9D9D9] h-[52px] w-[52px] rounded-full" />
                    <div className="flex flex-col justify-between w-full gap-[6px]">
                      <h1 className="text-[14px] font-medium leading-[16.41px]">
                        {testimonial.userName}
                      </h1>
                      <h1 className="text-[14px] font-medium text-[#00000099] leading-[16.41px]">
                        {testimonial.address}
                      </h1>
                    </div>
                  </div>
                  <div className="flex flex-row relative justify-start items-start bottom-[12px] gap-[10px]">
                    <img
                      src={edit}
                      alt="edit"
                      onClick={() => openEditForm(testimonial)}
                      className="cursor-pointer hover:bg-slate-100 hover:scale-105 hover:transition-transform"
                    />

                    <img
                      src={trash}
                      alt="trash"
                      onClick={() => handleDelete(testimonial.id)}
                      className="cursor-pointer hover:bg-slate-100 hover:scale-105 hover:transition-transform"
                    />
                  </div>
                </div>
                <div className="text-[14px] font-normal pb-[10px] leading-[16.41px] px-4 text-[#000000CC] font-roboto w-full text-justify line-clamp-3">
                  {testimonial.reviewText}
                </div>
              </div>
            </div>
          ))}

          {/* Add More Div */}
          <div
            className="w-[630px] h-[130px] text-[#00000066] border-spacing-2 text-3xl font-roboto font-bold rounded-md border-2 border-dashed border-[#000000] shadow-md shadow-[#0000004D] flex items-center justify-center cursor-pointer"
            onClick={() => {
              setShowForm(true);
              setIsEditing(false); // Add mode
              setFormData({
                id: 0,
                reviewText: "",
                userName: "",
                rating: "",
                image: "",
                bookingSubcategory: "",
                address: "",
              });
            }}
          >
            + Add New
          </div>
        </div>

        {/* Modal for Add/Edit Testimonial */}
        {showForm && (
          <form onSubmit={isEditing ? handleEditTestimonial : handleAddTestimonial}>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white w-[502px] h-[574px] flex flex-col items-center rounded-lg">
                <div className="h-[522px] p-6 flex flex-col items-center">
                  <div>
                    <h2 className="text-[24px] font-bold">{isEditing ? "Edit" : "Add New"} Testimonial</h2>
                  </div>
                  <div className="w-[449px] h-[411px] ">
                    {/* Input fields */}
                    <div className="relative flex flex-col w-full mt-[24px]">
                      <label className="absolute -top-2 left-2 text-[#000000D9] bg-[#FFFFFF] px-[12px] text-[14px] font-medium leading-[16.41px]">
                        User Name
                      </label>
                      <input
                        type="text"
                        name="userName"
                        value={formData.userName}
                        onChange={handleInputChange}
                        required={true}
                        placeholder="Enter the username for custom testimonials"
                        className="pl-[19px] border border-1 text-[14px] font-normal border-[#000000] w-full rounded-md h-12 text-black focus:outline-none placeholder:text-[#00000080]"
                      />
                    </div>
                     {/* Custom Text */}
                     <div className="relative flex flex-col w-full mt-12">
                      <label className="absolute -top-2 left-2 text-[#000000D9] bg-[#FFFFFF] px-[12px] text-[14px] font-medium leading-[16.41px]">
                        Custom Text
                      </label>
                      <textarea
                        name="reviewText"
                        value={formData.reviewText}
                        onChange={handleInputChange}
                        required={true}
                        placeholder="Enter the text for custom testimonials"
                        className="pl-[19px] border pt-4 border-1 text-[14px] font-normal border-[#000000] w-full rounded-md h-[115px] text-black focus:outline-none placeholder:text-[#00000080]"
                      />
                    </div>
                    {/* Address */}
                    <div className="relative flex flex-col w-full mt-12">
                      <label className="absolute -top-2 left-2 text-[#000000D9] bg-[#FFFFFF] px-[12px] text-[14px] font-medium leading-[16.41px]">
                        Address
                      </label>
                      <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                        required={true}
                        placeholder="Enter the address details of the user"
                        className="pl-[19px] border border-1 text-[14px] font-normal border-[#000000] w-full rounded-md h-12 text-black focus:outline-none placeholder:text-[#00000080]"
                      />
                    </div>
                    {/* Subcategory ID */}
                    <div className="relative flex flex-col w-full mt-12">
                      <label className="absolute -top-2 left-2 text-[#000000D9] bg-[#FFFFFF] px-[12px] text-[14px] font-medium leading-[16.41px]">
                        Subcategory - id
                      </label>
                      <input
                        type="text"
                        name="subcategoryId"
                        value={formData.subcategoryId}
                        onChange={handleInputChange}
                        required={true}
                        placeholder="Enter the subcategory ID for custom testimonials"
                        className="pl-[19px] border border-1 text-[14px] font-normal border-[#000000] w-full rounded-md h-12 text-black focus:outline-none placeholder:text-[#00000080]"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-row bg-black items-center h-[52px] rounded-b-lg w-full">
                  <button
                    type="button"
                    className="bg-gray-300 text-[#000000B2] h-full w-1/2 rounded-bl-lg font-roboto text-lg font-normal leading-[21.09px] py-4"
                    onClick={() => setShowForm(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-[#065668] text-white w-1/2 h-full rounded-br-lg font-roboto text-lg font-medium leading-[21.09px] py-4"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ManageTestimonials;
