import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import customFetch from "../../utils/axios";

const initialState = {
  isLoadingTesimonials: false,
  testimonials: [],
};

// GET ALL Testimonials
export const getTestimonials = createAsyncThunk(
  "testimonials/getTestimonials",
  async (_, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get("api/testimonial", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("GET Testimonials API Response:", resp);
      return resp?.data; // Ensure to return the array of testimonials
    } catch (error) {
      console.error("Error fetching testimonials:", error);
      return thunkAPI.rejectWithValue(
        error.response?.data || "An error occurred while fetching testimonials."
      );
    }
  }
);

// POST New Testimonial
export const postTestimonials = createAsyncThunk(
  "testimonials/postTestimonials",
  async (contentData, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.post("api/testimonial", contentData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("POST Testimonials API Response:", resp);
      return resp?.data; // Return the new testimonial data
    } catch (error) {
      console.error("Error posting testimonials:", error);
      return thunkAPI.rejectWithValue(
        error.response?.data || "An error occurred while posting the testimonial."
      );
    }
  }
);

// DELETE Testimonial
export const deleteTestimonials = createAsyncThunk(
  "testimonials/deleteTestimonials",
  async (testimonialId, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      await customFetch.delete(`api/testimonial/${testimonialId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("DELETE Testimonials API Response: Success");
      return testimonialId; // Return the ID of the deleted testimonial
    } catch (error) {
      console.error("Error deleting testimonial:", error);
      return thunkAPI.rejectWithValue(
        error.response?.data || "An error occurred while deleting the testimonial."
      );
    }
  }
);

// PUT Update Testimonial
export const updateTestimonials = createAsyncThunk(
  "testimonials/updateTestimonials",
  async ({ testimonialId, contentData }, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.put(`api/testimonial/${testimonialId}`, contentData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("PUT Testimonials API Response:", resp);
      return resp?.data; // Return the updated testimonial data
    } catch (error) {
      console.error("Error updating testimonial:", error);
      return thunkAPI.rejectWithValue(
        error.response?.data || "An error occurred while updating the testimonial."
      );
    }
  }
);

const TestiMonialsSlice = createSlice({
  name: "testimonials",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoadingTesimonials = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // GET Testimonials
      .addCase(getTestimonials.pending, (state) => {
        state.isLoadingTesimonials = true;
      })
      .addCase(getTestimonials.fulfilled, (state, { payload }) => {
        state.isLoadingTesimonials = false;
        state.testimonials = payload; // Store the fetched testimonials
      })
      .addCase(getTestimonials.rejected, (state, { payload }) => {
        state.isLoadingTesimonials = false;
        console.error("GET Testimonials error:", payload);
      })

      // POST Testimonials
      .addCase(postTestimonials.pending, (state) => {
        state.isLoadingTesimonials = true;
      })
      .addCase(postTestimonials.fulfilled, (state, { payload }) => {
        state.isLoadingTesimonials = false;
        state.testimonials.push(payload); // Add the new testimonial to the state
      })
      .addCase(postTestimonials.rejected, (state, { payload }) => {
        state.isLoadingTesimonials = false;
        console.error("POST Testimonials error:", payload);
      })

      // DELETE Testimonials
      .addCase(deleteTestimonials.pending, (state) => {
        state.isLoadingTesimonials = true;
      })
      .addCase(deleteTestimonials.fulfilled, (state, { payload }) => {
        state.isLoadingTesimonials = false;
        state.testimonials = state.testimonials.filter(
          (testimonial) => testimonial.id !== payload
        ); // Remove the deleted testimonial from the state
      })
      .addCase(deleteTestimonials.rejected, (state, { payload }) => {
        state.isLoadingTesimonials = false;
        console.error("DELETE Testimonials error:", payload);
      })

      // PUT Update Testimonials
      .addCase(updateTestimonials.pending, (state) => {
        state.isLoadingTesimonials = true;
      })
      .addCase(updateTestimonials.fulfilled, (state, { payload }) => {
        state.isLoadingTesimonials = false;
        const index = state.testimonials.findIndex(
          (testimonial) => testimonial.id === payload.id
        );
        if (index !== -1) {
          state.testimonials[index] = payload; // Update the testimonial in the state
        }
      })
      .addCase(updateTestimonials.rejected, (state, { payload }) => {
        state.isLoadingTesimonials = false;
        console.error("PUT Testimonials error:", payload);
      });
  },
});

export default TestiMonialsSlice.reducer;
